//base url
export const LOGIN_PAGE = 'https://pems.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://pems.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://api.phillipsoutsourcing.net';
//aws bucket
export const DOCUMENT_BASE_URL = 'https://s3.us-east-2.amazonaws.com/api.phillipsoutsourcing.net';
//Company
export const COMPANIES_URL = '/companies';

export const COMPANIES_EMPLOYEE_JOB_HISTORY = '/companies-employee-jobhistory';

export const ANNOUNCEMENTS_URL = '/announcements';
export const SEARCH_ANNOUNCEMENT_URL='/search-announcements';
export const ACTIVE_COMPANY_URL = '/active-companies';
export const SEARCH_COMPANY_PAY_GRADES = '/search-company-pay-grades';
export const SEARCH_CITY = '/search-cities';
export const SEARCH_STATE = '/search-states';
export const CURRENCY_SYMBOLS = '/currency-symbols';
export const SET_BASE_CURRENCY = '/set-base-currency';
export const SEARCH_LOCAL_GOVT = '/search-local-governments';
export const DEPARTMENTS_URL = '/departments';
export const SUPERVISOR_URL = '/supervisors';
export const COMPANY_FOLDER_URL = '/company-folders';
export const COMPANY_FILE_URL = '/company-files';
export const REMOVE_COMPANY_FILE = '/remove-company-file';
export const JOB_ROLES_URL = '/job-roles';
export const LEAVE_URL = '/leaves';
export const LEAVE_ORDERING = 'updateOrder';
export const EVENT_URL = '/events';
export const USER_EVENT_URL = '/get-user-events';
export const EXPORT_EVENT_FEEDBACK = '/export-event-feedback';
export const EVENT_FEEDBACK_ANALYTICS = '/event-feedback-analytics';
export const EVENT_RESPONDENTS = '/event-feedback-respondents';
export const CONFIDENTIAL_NOTES = '/confidential-notes';
export const COMPANY_CASES = '/company-cases';
export const APPRAISAL_DOCUMENTS = '/appraisal-documents';
export const TRAINING_SCHEDULES = '/training-schedules';
export const CASE_TYPES = '/case-types';
export const GET_LEAVE_APPLICATIONS = '/leave-applications';
export const GET_LEAVE_APPLICATION_OTHERS = '/leave-application-others';
export const GET_APPLICABLE_LEAVES = '/get-applicable-leaves';
export const APPROVE_LEAVE_REQUESTS = '/approve-leave-requests';
export const REJECT_LEAVE_REQUESTS = '/reject-leave-requests';
export const APPRAISAL_URL = '/appraisals';


//user
export const USERS_COMPANIES_URL = '/get-user-companies';
export const USERS_URL = '/users';
export const GET_USER_AUTHORIZATION = '/get-user-authorization';
export const SEARCH_USERS = '/search-users';
//general urls
export const COUNTRIES_URL = '/countries';

//employees urls
export const EMPLOYEE_STATUSES = '/employee-statuses';
export const VOTING_RESULTS = '/get-voting-results';
export const GET_TODAY_CELEBRANTS = '/get-today-celebrants';
export const GET_HOLIDAYS = '/get-holidays';
export const GET_CELEBRANTS = '/coming-celebrants';
export const EMPLOYEE_QUERIES = '/employee-queries';
export const COMPANY_EXITS = '/company-exits';
export const SAVE_QUERY_RESPONSE = '/save-query-response';
export const QUERY_ACTION = '/query-action';
export const BLACK_LISTS = '/blacklists';
export const BLACKLIST_EMPLOYEE = '/blacklist-employee';
export const WHITELIST_EMPLOYEE = '/whitelist-employee';
export const GENERAL_SEARCH_EMPLOYEES = '/general-search-employees';
export const GENERAL_SEARCH_CANDIDATES = '/general-search-candidates';
export const GENERAL_SEARCH_VACCINATION = '/general-search-vaccinations';
export const GENERAL_SEARCH_VERIFICATIONS = '/general-search-verifications';
export const REMOVE_EMPLOYEE = '/remove-employee';
export const EMPLOYEE_URL = '/employees';
export const EMPLOYEES_BY_SUPERVISORS = '/get-supervisors-employees';
export const UNVERIFIED_EMPLOYEE_URL = '/get-unverified-employees';
export const SAVE_EMPLOYEE_BANK = '/save-employee-bank';
export const SEARCH_COMPANY_EMPLOYEE = '/search-company-employee';
export const GET_EMPLOYEE_PAY_RATES = '/employee-pay-rates';
export const GET_EMPLOYEE_VARIATIONS = '/get-employee-variations';
export const EMPLOYEE_GRADE_LEVELS = '/employee-grade-levels';
export const SEARCH_GRADE_LEVELS = '/search-grade-levels';
export const GET_EMPLOYEE_VARIATION_DETAILS = '/get-employee-variation-details';
export const SAVE_EMPLOYEE_MONTHLY_VARIATION = '/save-employee-monthly-variation';
export const REMOVE_EMPLOYEE_VARIATION = '/remove-employee-variation';
export const EMPLOYEE_VERIFICATIONS = '/employee-verifications';
export const GET_VERIFICATION_STATISTICS = '/get-verification-statistics';
export const GET_EMPLOYEE_INFO = '/get-employee-info';
export const EMPLOYEE_DEFAULT_ITEMS = '/employee-default-items';
export const SAVE_EMPLOYEE_DEFAULT_ITEMS = '/save-employee-default-items';
export const GENERATE_EMPLOYEE_NUMBER = '/generate-new-employee-number';
export const EXPORT_EMPLOYEE_LIST = '/export-employee-list';
export const EXPORT_EMPLOYEE_VALIDATIONS = '/export-employee-validations';
export const EXPORT_EMPLOYEE_LIST_OTHERS = '/export-employee-list-others';
export const GET_EMPLOYEE_COLUMNS = '/get-employee-columns';
export const SALARY_ADVANCE_URL = '/advances';
export const BULK_DELETE_SALARY_ADVANCE = '/bulk-delete-salary-advance';
export const TOGGLE_SALARY_ADVANCE_STATUS = '/toggle-salary-advance-status';
export const MERGE_CONFIRMATION_LIST = '/merge-confirmation-list';
export const TOGGLE_CHECK_IN = '/toggle-check-in';
export const TOGGLE_FILE_VISIBILITY = '/toggle-file-visibility';
export const GET_EMPLOYEE_TIME = '/get-employee-time';
export const REGULARIZE_ATTENDANCE = '/regularize-attendance';
export const REQUEST_REGULARIZATION = '/request-attendance-regularization';
export const REGULARIZATION_REQUESTS = '/get-regularization-requests';
export const APPROVE_REGULARIZATION = '/approve-attendance-regularization';
export const DISAPPROVE_REGULARIZATION = '/disapprove-attendance-regularization';
export const GET_EMPLOYEE_ATTENDANCE = '/get-employee-attendance';
export const GET_EMPLOYEE_ATTENDANCE_REPORT = '/get-all-employees-attendance-report';
export const GET_DAILY_ANALYSIS_REPORT = '/get-daily-analysis-attendance';
export const ADD_ANNOUNCEMENT_REACTION = '/add-reaction';
export const ANNOUNCEMENT_COMMENTS_URL = '/announcement-comments';
export const ANNOUNCEMENT_REMINDER_URL = '/announcement-reminders';
export const GET_EMPLOYEE_DOCUMENTATION = '/get-employee-documentations';
export const SEARCH_EMPLOYEE_DOCUMENTATION = '/search-employee-documentations';
export const GET_EMPLOYEE_DOCUMENT_STATUS = '/get-employee-document-status';
export const EXPORT_EMPLOYEE_DOCUMENT_STATUS = '/export-employee-document-status';
export const SEARCH_QUERIES = '/search-queries';
export const SEARCH_COMPANY_QUERIES = '/search-company-queries';
export const CLONE_EMPLOYEE_MONTHLY_VARIATION ='/clone-employee-monthly-variation'

//candidates urls
export const CANDIDATE_URL = '/candidates';
export const REMOVE_CANDIDATE_PROFILE = '/remove-candidates-profile';
export const REMOVE_SUPERVISORS = '/remove-supervisors';
export const ONBOARDING_TASKS = '/onboarding-tasks';
export const MAKE_DEFAULT_TASK = '/make-default-task';
export const ONBOARDING_DOCUMENTS = '/onboarding-documents';
export const HOLIDAYS = '/holidays';
export const GET_HOLIDAY_SETTINGS = '/get-holiday-settings';
export const SAVE_HOLIDAY_SETTINGS = '/save-holiday-settings';
export const DOCUMENT_TITLES = '/document-titles';
export const APPROVE_CANDIDATE_ONBOARDING = '/approve-candidates-onboarding';
export const ASSIGN_CANDIDATES_TASK = '/assign-candidates-task';
export const ASSIGN_EMPLOYEES_SUPERVISOR = '/assign-employees-supervisor';
export const ASSIGN_CANDIDATES_LETTER = '/assign-candidates-letter';
export const ASSIGN_PAY_STRUCTURE = '/assign-pay-structure';
export const SEND_LETTER_TO_CANDIDATES = '/send-candidates-letter';
export const DOWNLOAD_CANDIDATE_OFFER_LETTER='/download-candidates-letter'
export const PROMOTE_CANDIDATES = '/promote-candidates';
export const SUBMIT_CANDIDATE_RECORD = '/submit-candidate-record';
export const CANDIDATE_TAG = '/tag_employee';
//letters urls
export const GET_LETTER_TITLES = '/get-letter-titles';
export const GET_LETTER_VARIABLES = '/get-letter-variables';
export const GET_APPRAISAL_TEMPLATE_VARIABLES = '/get-appraisal-variables';
export const LETTER_URL = '/letters';


//statistics
export const ESM_DASHBOARD_STATISTICS = '/esm-dashboard-statistics';
export const GET_NEW_HIRES = '/get-new-hires';
export const GET_DEPARTMENT_MEMBERS = '/get-department-members';


//banks
export const BANKS = '/banks';
export const RESOLVE_BANK_ACCOUNT = '/api/resolve';
export const PFAS = '/pfa-names';

//company payroll
export const EXCHANGE_RATES = '/exchange-rates';
export const GET_PAYROLL_RATES = '/get-payroll-rates';
export const UPDATE_PAYROLL_RATES = '/update-payroll-rates';
export const COMPANY_PAYROLL_ITEMS = '/company-payroll-items';
export const PAYROLL_COMPUTATIONS = '/payroll-computations';
export const PAY_GRADES = '/pay-grades';
export const COMPANY_ITEMS_FOR_PAYROLL = '/company-items-for-payroll';
export const PAY_STRUCTURES = '/pay-structures';
export const GET_PAYROLL_EXCLUSIONS = '/get-payroll-exclusions';
export const EXCLUDE_AN_EMPLOYEE = '/exclude-employee';
export const REMOVE_FROM_EXCLUSION = '/remove-from-exclusion';
export const CLEAR_EXCLUSION_LIST = '/clear-exclusion-list';
export const IMPORT_MASS_EXCLUSION_FILE = '/import-mass-exclusion-files';

//data urls
export const EXPORT_PAY_GRADE_TEMPLATE = '/export-pay-grade-template';
export const EXPORT_LEAVE_APPLICATIONS = '/export-leave-applications';
export const EXPORT_LEAVE_APPLICATIONS_OTHERS = '/export-leave-applications-others';
export const EXPORT_MASS_ASSIGNMENT_TEMPLATE = '/export-grade-assignment-template';
export const EXPORT_CONTACT_DETAILS_TEMPLATE = '/export-contact-details-template';
export const EXPORT_LEAVE_APPLICATIONS_TEMPLATE = '/export-leave-applications-template';
export const EXPORT_PFA_NUMBERS_TEMPLATE = '/export-pfa-numbers-template';
export const EXPORT_NHF_NUMBERS_TEMPLATE = '/export-nhf-numbers-template';
export const EXPORT_TIN_NUMBERS_TEMPLATE = '/export-tin-numbers-template';
export const EXPORT_SALARY_ADVANCE_TEMPLATE = '/export-salary-advance-template';
export const EXPORT_SUPERVISORS_TEMPLATE = '/export-supervisors-template';
export const EXPORT_SALARY_ADVANCE = '/export-salary-advance';
export const EXPORT_SUPERVISORS = '/export-supervisors';
export const EXPORT_BRANCHES = '/export-branches';
export const EXPORT_VERIFICATION_LISTS = '/export-employee-verifications';
export const EXPORT_BLACKLIST_REPORT = '/export-blacklist-report';
export const EXPORT_CASES_REPORT = '/export-cases-report';
export const EXPORT_COVID_VACCINATIONS = '/export-covid-vaccinations';
export const EXPORT_MULTI_EXITS_TEMPLATE = '/export-multi-exits-template';
export const EXPORT_HIRING_INFO_TEMPLATE = '/export-hiring-info-template';
export const EXPORT_ACCOUNT_NUMBERS_TEMPLATE = '/export-account-numbers-template';
export const EXPORT_MASS_EXCLUSION_TEMPLATE = '/export-mass-exclusion-template';
export const EXPORT_PAY_RATE_TEMPLATE = '/export-pay-rate-template';
export const EXPORT_ASSIGN_SUPERVISOR_TEMPLATE = '/export-assign-supervisor-template';
export const SCAN_PAY_GRADE_FILE = '/scan-pay-grade-files';
export const SCAN_GRADE_ASSIGN_FILE = '/scan-grade-assignment-files';
export const SCAN_CONTACT_DETAILS_FILE = '/scan-contact-details-files';
export const SCAN_LEAVE_APPLICATIONS_FILE = '/scan-leave-applications-files';
export const SCAN_PFA_NUMBERS_FILE = '/scan-pfa-numbers-files';
export const SCAN_NHF_NUMBERS_FILE = '/scan-nhf-numbers-files';
export const SCAN_TIN_NUMBERS_FILE = '/scan-tin-numbers-files';
export const SCAN_SALARY_ADVANCE_FILE = '/scan-salary-advance-files';
export const SCAN_SUPERVISORS_FILE = '/scan-supervisors-files';
export const SCAN_MULTI_EXITS_FILE = '/scan-multi-exits-files';
export const SCAN_HIRING_INFO_FILE = '/scan-hiring-info-files';
export const SCAN_ACCOUNT_NUMBERS_FILE = '/scan-account-numbers-files';
export const SCAN_MASS_EXCLUSION_FILE = '/scan-mass-exclusion-files';
export const SCAN_PAY_RATE_FILE = '/scan-pay-rate-files';
export const SCAN_ASSIGN_SUPERVISOR_FILE = '/scan-assign-supervisor-files';
export const IMPORT_PAY_GRADE_FILE = '/import-pay-grade-files';
export const IMPORT_GRADE_ASSIGN_FILE = '/import-grade-assignment-files';
export const IMPORT_CONTACT_DETAILS_FILE = '/import-contact-details-files';
export const IMPORT_LEAVE_APPLICATIONS_FILE = '/import-leave-applications-files';
export const IMPORT_PFA_NUMBERS_FILE = '/import-pfa-numbers-files';
export const IMPORT_NHF_NUMBERS_FILE = '/import-nhf-numbers-files';
export const IMPORT_TIN_NUMBERS_FILE = '/import-tin-numbers-files';
export const IMPORT_SALARY_ADVANCE_FILE = '/import-salary-advance-files';
export const IMPORT_SUPERVISORS_FILE = '/import-supervisors-files';
export const IMPORT_MULTI_EXITS_FILE = '/import-multi-exits-files';
export const IMPORT_PAY_RATE_FILE = '/import-pay-rate-files';
export const IMPORT_ASSIGN_SUPERVISOR_FILE = '/import-assign-supervisor-files';
export const IMPORT_HIRING_INFO_FILE = '/import-hiring-info-files';
export const IMPORT_ACCOUNT_NUMBERS_FILE = '/import-account-numbers-files';
export const EMPLOYEE_ANNUAL_GRADES = '/employee-annual-grades';
export const EXPORT_EMPLOYEE_TEMPLATE = '/export-employees-template';
export const EXPORT_CANDIDATE_TEMPLATE = '/export-candidates-template';
export const EXPORT_VARIATION_TEMPLATE = '/export-variations-template';
export const SCAN_EMPLOYEE_FILE = '/scan-employees-files';
export const SCAN_CANDIDATE_FILE = '/scan-candidates-files';
export const SCAN_VARIATION_FILE = '/scan-variations-files';
export const IMPORT_EMPLOYEE_FILE = '/import-employees-files';
export const IMPORT_CANDIDATE_FILE = '/import-candidates-files';
export const IMPORT_VARIATION_FILE = '/import-variations-files';
export const IMPORT_SUPPORTING_DOCUMENT = '/import-supporting-documents';
export const EXPORT_VARIATIONS = '/export-variations';


//payroll
export const GET_PAYROLL_REQUIREMENTS = '/get-payroll-requirements';
export const GET_PENDING_EMPLOYEE_PAYMENTS = '/get-pending-payments';
export const GET_PAYROLL_EMPLOYEES = '/get-payroll-employees';
export const RUN_PAYROLL_PROCESS = '/run-payroll';
export const GET_COMPANY_PAYROLLS = '/get-company-payrolls';
export const REMOVE_SUSPENDED_EARNINGS = '/remove-suspended-employee-earnings';
export const REMOVE_EMPLOYEE_EARNINGS = '/remove-employee-earnings';
export const GET_DELETED_EARNINGS = '/get-deleted-earnings';
export const REVERSE_ALL_DELETED_EARNINGS = '/reverse-all-deleted-earnings';
export const REVERSE_DELETED_EARNINGS = '/reverse-deleted-earnings';
export const SEND_APPROVAL_NOTIFICATION = '/send-payroll-approval';

//export const SEND_APPROVAL_NOTIFICATION = 'http://popip.westus.cloudapp.azure.com:82/api/send-payroll-approval';
export const REMOVE_PAYROLL = '/remove-payroll';
export const EMPLOYEE_NOTIFICATIONS = '/employee-notifications';
export const GET_EMPLOYEE_PAYCHECKS = '/get-employee-paychecks';
export const GET_COMPANY_PAYCHECKS = '/get-company-paychecks';
export const PAY_EMPLOYEES = '/pay-employees';
export const PAY_INDIVIDUAL_EMPLOYEE = '/pay-individual-employee';
export const SAVE_PAYSTUB_SCHEDULE = '/save-paystub-schedule';
export const GET_EMPLOYEE_PAYSTUB = '/get-employee-paystub';
export const MAIL_PAYSTUB = '/mail-paystub';
export const MAIL_EXTERNAL_PAYSTUB = '/mail-external-paystub';

export const GET_PAYROLL_ITEMS = '/get-payroll-items';
export const SEARCH_PAYROLL_ITEMS = '/search-payroll-items';
export const GET_EXISTING_PAYROLL_ITEMS = '/get-existing-payroll-items';
export const GET_PAYROLL_ITEM = '/get-payroll-item';
export const GET_GRADE_ITEMS = '/get-grade-items';
export const GET_ACTIVE_ITEMS = '/get-active-items';
export const GET_ITEM_TYPES = '/get-item-types';
export const SAVE_PAYROLL_ITEMS = '/save-payroll-items';
export const SCAN_PAYROLL_ITEMS = '/scan-payroll-items';
export const IMPORT_PAYROLL_ITEMS = '/import-payroll-items';
export const GET_PAYROLL_REGISTER = '/get-payroll-register';
export const GET_PAYROLL_REGISTER_FOR_EXPORT = '/get-payroll-register-export';
export const GET_PAYROLL_SETUP = '/get-payroll-setup';
export const GET_PAYSTUB_SETTINGS = '/get-paystub-settings';
export const SAVE_PAYSTUB_SETTINGS = '/save-paystub-settings';
export const SAVE_PAYROLL_SETUP = '/save-payroll-setup';
export const GET_DEFAULT_SELECTED_ITEMS = '/get-default-selected-items';
export const TOGGLE_PAYROLL_APPROVAL = '/toggle-payroll-approval';

export const PAYROLL_APPROVAL_INFO = '/payroll-approval-info';
export const GET_EARNINGS_COMMENTS = '/get-earnings-comments';
export const SAVE_EARNINGS_COMMENT = '/save-earnings-comment';
export const REMOVE_COMMENT = '/remove-earning-comment';
export const GET_SELECTED_DISAPPROVAL = '/get-selected-disapproval';
export const UPDATE_APPROVALS = '/update-approvals';
export const GET_PAYROLL_APPROVAL_STATUS = '/get-payroll-approval-status';
export const GET_YTD_EARNINGS = '/get-ytd-earnings';
export const GET_PAYROLL_WAREHOUSING = '/get-payroll-warehousing';
export const REMOVE_YTD_EARNINGS = '/remove-ytd-earnings';
export const GET_YTD_EARNINGS_FOR_EXPORT = '/get-ytd-earnings-export';
export const GET_PAYROLL_WAREHOUSING_FOR_EXPORT = '/get-payroll-warehousing-export';


export const GET_GENERAL_JOURNAL_REPORTS = '/get-general-journal-reports';
export const GET_GENERAL_LEDGER_REPORTS = '/get-general-ledger-reports';
export const EXPORT_PAYITEM_TEMPLATE = '/export-payitem-template';
export const REMOVE_PAYROLL_ITEM = '/remove-payroll-item';
export const GET_PAYROLL_COMPUTATIONS = '/get-payroll-computations';
export const REMOVE_COMPUTATION = '/remove-computations';
export const TOGGLE_COMPUTATION = '/toggle-computation';
export const GET_COMPUTATIONS_VARIABLES = '/get-computations-variables';
export const SAVE_PAYROLL_COMPUTATIONS = '/save-payroll-computations';
export const GET_ACTIVE_FORMULAS = '/get-active-formulas';
export const ADD_PAYROLL_APPROVER = '/add-payroll-approver';
export const ADD_PAYROLL_NOTIFIER = '/add-payroll-notifier';
export const ADD_VARIATION_NOTIFIER = '/add-variation-notifier';
export const ADD_PAYMENT_NOTIFIER = '/add-payment-notifier';
export const ADD_INIT_PAYMENT_NOTIFIER = '/add-init-payment-notifier';
export const ADD_VARIATION_APPROVER = '/add-variation-approver';
export const GET_PAYROLL_APPROVERS = '/get-payroll-approvers';
export const GET_PAYROLL_NOTIFIERS = '/get-payroll-notifiers';
export const GET_VARIATION_NOTIFIERS = '/get-variation-notifiers';
export const GET_PAYMENT_NOTIFIERS = '/get-payment-notifiers';
export const GET_INIT_PAYMENT_NOTIFIERS = '/get-init-payment-notifiers';
export const GET_VARIATION_APPROVERS = '/get-variation-approvers';
export const SAVE_APPROVER_ORDER = '/save-approver-order';
export const SAVE_NOTIFIER_ORDER = '/save-notifier-order';
export const SAVE_VARIATION_APPROVER_ORDER = '/save-variation-approver-order';
export const REMOVE_VARIATION_APPROVER = '/remove-variation-approver';
export const REMOVE_PAYROLL_APPROVER = '/remove-payroll-approver';
export const REMOVE_PAYROLL_NOTIFIER = '/remove-payroll-notifier';
export const REMOVE_VARIATION_NOTIFIER = '/remove-variation-notifier';
export const REMOVE_PAYMENT_NOTIFIER = '/remove-payment-notifier';
export const REMOVE_INIT_PAYMENT_NOTIFIER = '/remove-init-payment-notifier';
export const GET_DAYS_TYPE = '/get-days-type';
export const VERIFY_EXISTING_VARIATIONS = '/verify-existing-variations';
export const EXPORT_ATTENDANCE_REPORT = '/export-attendance-report';


//data urls


export const EXPORT_YTD_BALANCE_TEMPLATE = '/export-ytd-balance-template';


export const SCAN_YTD_BALANCE_FILE = '/scan-ytd-balance-files';


export const IMPORT_YTD_BALANCE_FILE = '/import-ytd-balance-files';

export const EXPORT_ACCOUNT_TEMPLATE = '/export-accounts-template';
export const EXPORT_PAYMENT_APPROVAL_LIST = '/export-payment-approval-list';
export const EXPORT_CONFIRMATION_LIST = '/export-confirmation-list';
export const EXPORT_ITEM_TEMPLATE = '/export-items-template';
export const APPROVE_ITEM_PAYMENT = '/approve-item-payment';
export const CONFIRM_ITEM_PAYMENT = '/confirm-item-payment';
export const GET_PAYROLL_CLIENTS = '/get-payroll-clients';
export const GET_APPROVED_PAYROLLS = '/get-approved-payrolls';
export const GET_PAYABLE_ITEMS = '/get-payable-items';

//variations
export const REMOVE_VARIATIONS = '/remove-variations';
export const VARIATIONS_URL = '/variations';
export const SEND_VARIATION_APPROVAL_NOTIFICATION = '/send-variations-notifications';
//export const SEND_VARIATION_APPROVAL_NOTIFICATION = 'http://popip.westus.cloudapp.azure.com:82/api/send-variations-notifications';
export const GET_VARIATION_STATISTICS = '/get-variation-statistics';
export const APPROVE_VARIATION = '/approve-variation';
export const GET_USER_APPROVER = '/get-user-approver';
export const GET_IMPORTED_VARIATIONS = '/get-imported-variations';
export const GET_SUBMITTED_VARIATIONS = '/get-submitted-variations';
export const GET_APPROVED_VARIATIONS = '/get-approved-variations';
export const SWITCH_VARIATION_COMPANY = '/switch-variation-company'
export const GET_IMPORTED_VARIATIONS_EXPORT = '/get-imported-variations-export';
export const DISAPPROVE_VARIATION = '/disapprove-variation';
export const GET_VARIATION_COMMENTS = '/get-variation-comments';
export const REMOVE_VARIATION_COMMENT = '/remove-variation-comment';
export const SAVE_VARIATION_COMMENT = '/save-variation-comment';
export const GET_VARIATION_DOCUMENTS = '/get-variation-documents';
export const REMOVE_VARIATION_DOCUMENT = '/remove-variation-documents';


export const SEND_DISAPPROVAL_NOTIFICATION = '/send-disapproval-notification';
//export const SEND_DISAPPROVAL_NOTIFICATION = 'http://popip.westus.cloudapp.azure.com:82/api/send-disapproval-notification';


export const GET_PAYROLL_JOURNAL_ENTRIES = '/get-payroll-journal-entries';

export const POST_JOURNAL_ENTRIES = '/post-journal-entries';

//exporting file

export const EXPORT_PAYROLL_SUMMARY = '/export-payroll-summary';
export const EXPORT_EARNING_SUMMARY = '/export-earning-summary';
export const EXPORT_CONTACT_LIST = '/export-contact-list';
export const EXPORT_JOURNAL = '/export-journal';
export const EXPORT_VOTES = '/export-votes';
export const EXPORT_PAYROLL_REGISTER = '/export-register';
export const REMOVE_FILE_FROM_DIRECTORY = '/remove-file-from-directory';
export const EXPORT_YTD_EARNINGS = '/export-ytd-earnings';
export const EXPORT_PAYROLL_WAREHOUSING = '/export-payroll-warehousing';

export const EXPORT_NHF_SCHEDULE = '/export-nhf-schedule';
export const EXPORT_NHF_SUMMARY = '/export-nhf-summary';
export const EXPORT_PENSION_SCHEDULE = '/export-pension-schedule';
export const EXPORT_NSITF_SCHEDULE = '/export-nsitf-schedule';
export const EXPORT_PENSION_SUMMARY = '/export-pension-summary';
export const GET_COMPANY_LOGS = '/company-logs';

//reporting
export const GET_PAYROLL_SUMMARIES = '/get-payroll-summaries';
export const GET_EARNING_SUMMARY = '/get-earning-summary';
export const GET_NHF_SCHEDULE = '/get-nhf-schedule';
export const GET_NHF_SUMMARY = '/get-nhf-summary';
export const GET_PENSION_SCHEDULE = '/get-pension-schedule';
export const GET_NSITF_SCHEDULE = '/get-nsitf-schedule';
export const GET_PENSION_SUMMARY = '/get-pension-summary';
export const GET_CONTACT_LIST = '/get-contact-list';
export const EXPORT_PAYROLL_REPORT = '/export-payroll-report';


//Taxes
export const GET_TAXES = '/get-taxes';
export const REMOVE_TAX_ITEM = '/remove-tax-item';
export const GET_TAX_ITEMS = '/get-tax-items';
export const ADD_TAX_ITEM = '/add-tax-item';

//tasks
export const TASKS_URL = '/tasks';
//employees

//candidate profile & set up
export const GET_EMPLOYEE_PROFILE = '/get-employee-profile';
export const GET_EMPLOYEE_EARNINGS = '/get-employee-earnings';
export const GET_CANDIDATE_TASKS = '/get-candidate-tasks';
export const NOTIFY_CANDIDATE_DISAPPROVAL = '/notify-candidate-disapproval';
export const MARK_TASK_COMPLETED = '/mark-task-completed';
export const APPROVE_CANDIDATE_TASK = '/approve-candidate-task';
export const DISAPPROVE_CANDIDATE_TASK = '/disapprove-candidate-task';
export const ALLOW_TASK_EDITING = '/allow-task-editing';
export const DISALLOW_TASK_EDITING = '/disallow-task-editing';
export const SAVE_EMPLOYEE_PROFILE = '/save-employee-profile';
export const EMPLOYEE_PROFILE = '/employees';
export const WORK_EXPERIENCES = '/work-experiences';
export const EMPLOYEE_EXITS = '/employee-exits';
export const UPDATE_EXIT_STAGE = '/update-exit-stage';
export const PROF_QUALIFICATION = '/qualifications';
export const NEXT_KINS = '/next-kins';
export const GUARANTORS = '/guarantors';
export const BENEFICIARIES = '/beneficiaries';
export const DEPENDENTS = '/dependents';
export const EMPLOYEE_FILES = '/employee-files';
export const UPDATE_DOCUMENT_TYPES = '/update-document-types';
export const EMPLOYEE_MULTI_UPLOADS = '/employee-multi-uploads';
export const VACCINATION_FILES = '/vaccination-files';
export const SCAN_EMPLOYEE_LIST = '/scan-employee-list';
export const SAVE_EMPLOYEE_LIST = '/save-employee-list';
export const GET_EMPLOYEE_FILES = '/get-employee-files';
export const SEARCH_EMPLOYEE_FILES = '/search-employee-files';
export const EDU_RECORDS = '/educational-records';
export const SEARCH_UNIVERSITY = '/search-universities';
export const SEARCH_COURSES = '/search-courses';
export const SEARCH_DEGREES = '/search-degrees';
export const DEGREE_CLASSES = '/degree-classes';
export const RELATIONSHIPS = '/relationships';
export const SEARCH_PERSONAL_TITLES = '/search-personal-titles';
export const GENOTYPES = '/genotypes';
export const BLOOD_GROUPS = '/blood-groups';

//Polling

export const MAKE_POLL_ACTIVE = '/make-poll-active';
export const POLL_URL = '/polls';
export const POLL_EMPLOYEES_URL = '/get-polls-employees';
export const SEARCH_POLL_EMPLOYEES = '/search-polls-employees';
export const VOTERS_GROUPS_URL = '/voters-groups';
export const ADD_ALL_NOMINEES = '/add-all-nominees';
export const REMOVE_ALL_NOMINEES = '/remove-all-nominees';
export const ADD_ALL_VOTERS = '/add-all-voters';
export const REMOVE_ALL_VOTERS = '/remove-all-voters';
export const GET_POLL_GROUP = '/get-poll-groups';
//validations

export const COMPANY_VALIDATIONS = '/company-validations';
export const VALIDATED_EMPLOYEES = '/validated-employees';
export const SEARCH_SUPERVISORS = '/search-supervisors';
export const SEARCH_SUPERVISORS_EMPLOYEES = '/search-supervisors-employees';

//Guarantors

export const GUARANTORS_VERIFICATION = '/guarantor-verifications';

//Help Desk

export const SUPPORT_METRICS = '/get-support-metrics';
export const SUPPORT_TICKETS = '/supports';
export const SUPPORT_CATEGORIES = '/get-support-categories';
export const SUPPORT_STATUS = '/get-support-status';

//Company Profile Verification

export const COMPANY_PROFILE_VERIFICATION_EXERCISE = '/company-profile-verifications'

export const GET_ACTIVE_PROFILE_VERIFICATION = '/get-active-profile-verification'
export const EMPLOYEE_PROFILE_VERIFICATION ='/employee-profile-verifications'

export const USER_GROUP_URL ='/user-groups'


